import Image from "next/image"
import Link from "next/link"
import { DrupalNode } from "next-drupal"

import { absoluteUrl } from "lib/utils"
import config from "tailwindcss/defaultConfig";

interface NodeCampaignTeaserProps {
    node: DrupalNode
}

export function NodeCampaignTeaser({ node, ...props }: NodeCampaignTeaserProps) {
    if( config.debug ) {
        console.log('NodeCampaignTeaser');
        console.log(node);
    }

    let link = node.path?.alias?node.path.alias:'/node/'+node.drupal_internal__nid;
    let image = node.field_hp_image.field_media_image.image_style_uri?.large ? node.field_hp_image.field_media_image.image_style_uri.large : absoluteUrl(node.field_hp_image.field_media_image.uri.url);
    return (
        <li className="campaign-item" {...props}>
            <Link href={link} title={node.title}>
                <Image src={image} alt={node.title} layout="fill"/>
                <div className="campaign-item-inner">
                    <p>{node.field_client}</p>
                    <h2>{node.title}</h2>
                </div>
            </Link>
        </li>
    )
}
