import Image from "next/image"
import { DrupalNode } from "next-drupal"

import {absoluteUrl, formatDate, processHTML} from "../../lib/utils";
import {NodeArticleTeaser} from "./node--article--teaser";
import {useTranslation} from "next-i18next";
import {Paragraphs} from "../paragraph/paragraphs";
import React from "react";
import config from "site.config";


export interface NodeArticleProps {
    node: DrupalNode,
    additionalContent: {
        featuredArticles: DrupalNode[]
    }
}

export function NodeArticle({ node, additionalContent, ...props }: NodeArticleProps) {
    if( config.debug) {
        console.log('NodeArticle');
        console.log(node);
    }

    const { t } = useTranslation('article');
    return (
        <>
            <div className="news-header">
                <h1>{node.title}</h1>
            </div>
            <div className="news-intro">
                <div className="news-intro-inner">
                    <div className="news-intro-date">{formatDate(node.created)}</div>
                    {node.field_perex && (
                        <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: processHTML(node.field_perex?.processed) }} />
                    )}
                </div>
            </div>
            <div className="news-content">
                {node.body?.processed && (
                    <div
                        dangerouslySetInnerHTML={{ __html: processHTML(node.body?.processed) }}
                        className="wysiwyg"
                    />
                )}
                { node.field_paragraphs.length > 0 &&
                    <Paragraphs paragraphs={node.field_paragraphs} key="paragraphs"/>
                }

            </div>

            {node.field_image?.uri.url && (
                <figure>
                    <Image
                        src={absoluteUrl(node.field_image.uri.url)}
                        width={768}
                        height={400}
                        alt={node.field_image.resourceIdObjMeta?.alt}
                        priority
                    />
                    {node.field_image.resourceIdObjMeta?.title && (
                        <figcaption className="py-2 text-sm text-center text-gray-600">
                        {node.field_image.resourceIdObjMeta?.title}
                        </figcaption>
                    )}
                </figure>
            )}
            {additionalContent?.featuredArticles && (
                <div className="news-more">
                    <h3>{t("Další novinky")}</h3>
                    <ul className="news-more-list">
                        {additionalContent.featuredArticles.map((node) => {
                            let color = (typeof node?.field_color?.field_color === 'undefined' ? 'red' : node.field_color.field_color);
                            return <li className={"news-item news-item--" + color} key={node.id}>
                                <NodeArticleTeaser node={node}/>
                            </li>
                        })}
                    </ul>
                </div>
            )}
        </>
    )
}
