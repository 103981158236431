import Image from "next/image"
import {DrupalNode} from "next-drupal"
import {absoluteUrl} from "lib/utils"
import ReactPlayer from "react-player"
import "video-react/dist/video-react.css";
import {useTranslation} from "next-i18next";
import config from "site.config"

import React from "react";
import {Paragraphs} from "../paragraph/paragraphs";


export interface NodeCampaignTenderProps {
    node: DrupalNode,
    tender
}

export function NodeCampaignTender({ node, tender, ...props }: NodeCampaignTenderProps) {

    if( config.debug ) {
        console.log('NodeCampaignTender');
        console.log(node);
    }

    const { t } = useTranslation('tender');

    return (
        <>
            <div className="project project-info">
                <div className="project-brand">
                    {node.field_client}
                </div>
                <h1 className="project-title">
                    {node.title}
                </h1>
                <div className="project-date">
                    {node.field_year}
                </div>
            </div>

            <div className="project">
                <div className="project-hero">
                    {node.field_video?.length > 0 && (
                        <div className="campaign-video">
                            {node.field_video.map((video) => (
                                <ReactPlayer
                                    url={video.field_media_oembed_video}
                                    key={video.id}
                                    controls={true}
                                />
                        ))}
                        </div>
                    )}

                {node.field_image?.uri.url && (
                    <Image
                        src={absoluteUrl(node.field_image.uri.url)}
                        width={1200}
                        height={600}
                        layout="intrinsic"
                        objectFit="cover"
                        className="rounded-lg" alt={node.field_image.resourceIdObjMeta.alt} />
                )}
                </div>

                <div className="project-about">
                {
                    node.field_paragraphs.length > 0 &&
                    <Paragraphs paragraphs={node.field_paragraphs} key="paragraphs"/>
                }
                </div>
                <div className="project-gallery"></div>
                <a className="project-backlink" onClick={tender} title={t("Back")}>
                    {t("Back")}
                </a>
            </div>
        </>
    )
}
