import {FormEvent, useState} from "react";
import config from "../../site.config";
import {useTranslation} from "next-i18next";
import YAML from "yaml";
import SpinnerLoading from "../../pages/Loading_small";
import {WebformField} from "./webform--field";



export function Webform({webform, ...props}) {

    const { t } = useTranslation('webform');
    const [ loading, setLoading ] = useState(false)
    const [showForm, setShowForm] = useState(true)

    if( webform && webform.status != "open" ) {
        let message_closed = t('Form is not available now.');
        if( webform?.settings?.form_close_message) {
            message_closed = webform.settings.form_close_message;
        }
        return (
            <>
                <div>{ message_closed }</div>
            </>
        );
    }

    let webform_id = webform.resourceIdObjMeta.drupal_internal__target_id;
    let form_elements = YAML.parse(webform.elements);
    const form_elements_keys = Object.keys(form_elements);

    const handleSubmit = async (event: FormEvent) => {
        // Stop the form from submitting and refreshing the page.
        event.preventDefault()
        setLoading(true);

        // Cast the event target to an html form
        const form = event.target as HTMLFormElement

        // Get data from the form.
        let data = {
            webform_id: webform_id,
        };
        form_elements_keys.map((element_key, index) => {
            if( form[element_key].hasOwnProperty('value') ) {
                data[element_key] = form[element_key].value;
            }
        });

        await fetch(`${config.drupalBaseUrl}/session/token`).then(result => {
            if (200 === result.status) {
                const csrfToken = result.body as unknown as string;
                fetch(`${config.drupalBaseUrl}/webform_rest/submit`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRF-Token": csrfToken
                    },
                    body: JSON.stringify(data)

                }).then(result => {
                    if( 200 == result.status) {
                        setShowForm(false)
                        setLoading(false)
                    }
                })
            }
        });

    }

    let classes = (webform?.settings?.form_attributes?.class ? webform.settings.form_attributes.class.join(' ') : '');
    let submit_text = ( webform.settings.form_attributes.hasOwnProperty('submit_text') ? webform.settings.form_attributes.submit_text : t('Jdu do toho!') );
    let message = ( webform.settings.form_attributes.hasOwnProperty('submit_message') ? webform.settings.form_attributes.submit_message : t('Děkujeme za odeslání') );

    return (
        <>
            {showForm?
                <>
                    <form onSubmit={handleSubmit} className={classes}>
                        {
                            form_elements_keys.map((element_key, index) => {
                                let element = form_elements[element_key];
                                element['#type'] = element['#type'] == 'textfield' ? 'text' : element['#type'];
                                if( ! element['#name'] ) {
                                    element['#name'] = element_key;
                                }
                                if( ! element['#id']){
                                    element['#id'] =  "form_field_"+element['#name'];
                                }
                                return(
                                    <WebformField element={element} key={"webform_field_"+element_key}/>
                                )
                            })
                        }
                                <button className="submit" type="submit">{submit_text}</button>
                            </form>
                    {loading && <SpinnerLoading/>}
                </>
                :
                <div>{message}</div>
            }
        </>
    );


}

export class WebformError extends Error {
    response: string;

    constructor(response: string) {
        super();

        this.response = response;
    }
}


/**
 * Errors returned by Drupal.
 */
export type WebformErrors = {
    [name: string]: string;
};
