import * as React from "react"
import { DrupalMenuLinkContent } from "next-drupal"
import CookieConsent from "react-cookie-consent";
import {Suspense} from "react";
import Loading from "../../pages/Loading";
import {FooterStudie} from "./footer--studie";

export interface LayoutTenderProps {
    menus: {
        footer: DrupalMenuLinkContent[]
    }
    children?: React.ReactNode
}

export function LayoutTender({ menus, children }: LayoutTenderProps) {
    return (
        <>
            <div className="reference-layout">
                <div className="container page-reference">
                    <Suspense fallback={<Loading />}>{children}</Suspense>
                </div>
            </div>
            <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
        </>
    )
}
